.bodychat {
  background-color: #282c34;
}

.sectionchat {
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 100vh;
  background-color: rgb(40, 37, 53);
}

.mainchat {
  padding: 10px;
  height: 80vh;
  margin: 10vh 0 10vh;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
}

main::-webkit-scrollbar {
  width: 0.25rem;
}

main::-webkit-scrollbar-track {
  background: #1e1e24;
}

main::-webkit-scrollbar-thumb {
  background: #6649b8;
}

.formchat {
  bottom: 0;
  background-color: rgb(24, 23, 23);
  width: 100%;
  max-width: 728px;
  white-space: nowrap;
  display: inline-block;
  border-radius: 25px;
  font-size: 1.5rem;
}

.inputchat {
  width: 70%;
  top: 8px;
  font-size: 1.5rem;
  background: rgb(58, 58, 58);
  white-space: nowrap;
  color: white;
  outline: none;
  border: none;
  align-items: center;
  display: inline-block;
  justify-content: center;
  position: relative;
  bottom: -5px;
}

.buttonchat {
  border: none;
  color: white;
  position: relative;
  display: inline-block;
  bottom: 25px;
  cursor: pointer;
  font-size: 1.25rem;
  width: 20%;
  background-color: rgb(56, 56, 143);
}

.buttonchat:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.sign-in {
  color: #282c34;
  background: white;
  max-width: 400px;
  margin: 0 auto;
}

.ulchat,
.lichat {
  text-align: left;
  list-style: none;
}

.pchat {
  margin-bottom: 12px;
  line-height: 24px;
  padding: 10px 20px;
  border-radius: 25px;
  position: relative;
  color: white;
  text-align: center;
  transition: height 2s;
}

.message {
  display: flex;
  align-items: center;
}

.sent p {
  color: black;
  background: #81caff;
  align-self: flex-end;
}
.received p {
  background: #e5e5ea;
  color: black;
}

.imgchat {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin: 2px 5px;
}
