// Icons
@import "./Pages/Dashboards/Shop/scss/style.scss";
@import "assets/linear/style";
@import "assets/pe7/pe-icon-7-stroke/css/pe-icon-7-stroke";

// BOOTSTRAP 4 VARIABLES

@import "assets/components/bootstrap4/functions";
@import "assets/components/bootstrap4/variables";
@import "assets/components/bootstrap4/mixins";

// ArchitectUI Themes Variables

@import "assets/themes/layout-variables";

// BOOTSTRAP 4 IMPORTS

@import "assets/components/bootstrap4/root";
@import "assets/components/bootstrap4/reboot";
@import "assets/components/bootstrap4/type";
@import "assets/components/bootstrap4/images";
@import "assets/components/bootstrap4/code";
@import "assets/components/bootstrap4/grid";
@import "assets/components/bootstrap4/tables";
@import "assets/components/bootstrap4/buttons";
@import "assets/components/bootstrap4/transitions";
@import "assets/components/bootstrap4/dropdown";
@import "assets/components/bootstrap4/button-group";
@import "assets/components/bootstrap4/input-group";
@import "assets/components/bootstrap4/custom-forms";
@import "assets/components/bootstrap4/nav";
@import "assets/components/bootstrap4/navbar";
@import "assets/components/bootstrap4/card";
@import "assets/components/bootstrap4/breadcrumb";
@import "assets/components/bootstrap4/pagination";
@import "assets/components/bootstrap4/badge";
@import "assets/components/bootstrap4/jumbotron";
@import "assets/components/bootstrap4/alert";
@import "assets/components/bootstrap4/progress";
@import "assets/components/bootstrap4/media";
@import "assets/components/bootstrap4/list-group";
@import "assets/components/bootstrap4/close";
@import "assets/components/bootstrap4/toasts";
@import "assets/components/bootstrap4/tooltip";
@import "assets/components/bootstrap4/popover";
@import "assets/components/bootstrap4/carousel";
@import "assets/components/bootstrap4/utilities";
// LAYOUT

@import "assets/layout/layout";

// UTILS

@import "assets/utils/helpers";
@import "assets/utils/backgrounds";
@import "assets/utils/animate";
@import "assets/utils/comps-animations";

// ELEMENTS

@import "assets/elements/buttons";
@import "assets/elements/dropdown";
@import "assets/elements/navs";
@import "assets/elements/badges";
@import "assets/elements/cards";
@import "assets/elements/tabs";
@import "assets/elements/loaders";
@import "assets/elements/progressbar";
@import "assets/elements/timeline";
@import "assets/elements/listgroup";
@import "assets/elements/forms";
@import "assets/elements/pagination";
@import "assets/elements/chat";

// DASHBOARD BOXES

@import "assets/widgets/chart-boxes/chart-boxes";
@import "assets/widgets/content-boxes/content-boxes";
@import "assets/widgets/profile-boxes/profile-boxes";

// PAGES

// Applications

@import "assets/applications/applications-base";

// Perfect Scrollbar

// RC Tabs
@import "assets/components/rc-tabs/rc-tabs";

// Hamburger Button
@import "assets/components/hamburgers/hamburgers";

// Drawer
@import "assets/components/drawer/drawer";

// Notifications
@import "assets/components/notifications/sweetalerts";
@import "assets/components/notifications/toastify";

// Modals
@import "assets/components/modals/rodal";

// Progress Bar

// Slick Carousel

// Block loading

// Popovers & Tooltips
@import "assets/components/popovers-tooltips/popovers-tooltips";

// DEMO

@import "assets/demo-ui/demo";

// Responsive

@import "assets/layout/responsive/responsive-base";

@import "App.css";

@import "3DStyles.css";

div.card {
  box-shadow: "0px 0px 0px 5px rgba(50,50,50, .8)";
}

.landingContent {
  background-position: center;
  width: 100%;
  height: 100%;
  position: absolute;
  align-self: center;
  font-family: "Courier New", Courier, monospace;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-left: 15px;
  opacity: 0.9;
  transition: opacity 5s;
  padding-right: 15px;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  background-color: black;
  -webkit-animation: fadein 5s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 5s; /* Firefox < 16 */
  -ms-animation: fadein 5s; /* Internet Explorer */
  -o-animation: fadein 5s; /* Opera < 12.1 */
  animation: fadein 5s;
}
@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 0.9;
  }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 0.9;
  }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 0.9;
  }
}

.fadeOut {
  -webkit-animation-name: fadeOut;
  animation-name: fadeOut;
  -webkit-animation-duration: 300ms;
  animation-duration: 300ms;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

@-webkit-keyframes fadeOut {
  0% {
    opacity: 0.9;
  }
  100% {
    opacity: 0;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 0.9;
  }
  100% {
    opacity: 0;
  }
}

div.card {
  font-size: larger;
}
.zoom {
  transition: transform 0.2s; /* Animation */
}

.zoom:hover {
  transform: scale(
    1.05
  ); /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
}
.calendarVar button {
  color: "black";
  background-color: "black";
}

@media screen and (max-width: 600px) {
  .videoDivide {
    height: 200px;
  }
}
@media screen and (max-width: 500px) {
  .AccountBackground {
    width: 105%;
  }
}

@media screen and (max-width: 400px) and (min-width: 300px) {
  .ModeratorBackground {
    width: 107%;
    position: relative;
    left: -11px;
  }
}

@media screen and (max-width: 452px) and (min-width: 400px) {
  .ModeratorBackground {
    width: 107%;
    position: relative;
    left: -14px;
  }
}
@media screen and (max-width: 475px) and (min-width: 452px) {
  .ModeratorBackground {
    width: 107%;
    position: relative;
    left: -14px;
  }
}
@media screen and (max-width: 492px) and (min-width: 475px) {
  .ModeratorBackground {
    width: 106%;
    position: relative;
    left: -14px;
  }
}

@media screen and (min-width: 600px) {
  .videoDivide {
    height: 400px;
  }
  .AccountBackground {
    width: 102%;
  }
  .ModeratorBackground {
    width: 100%;
  }
}
#root
  > div
  > div.app-header.header-shadow
  > div.app-header__content
  > div.app-header-right
  > div.header-btn-lg.pr-0
  > div
  > div
  > div:nth-child(1) {
  position: relative;
  top: -4px;
}
#root > div > div.app-main > div.app-sidebar.sidebar-shadow > div.app-header__logo {
  display: none;
}

#particle-js {
  width: 100%;
  height: 100%;
  background: transparent;
  transition-duration: 1s;
  transition: opacity 2s;
}

#firebaseui-auth-container
  > span
  > div
  > div
  > div.tab-pane.active
  > div
  > div
  > div:nth-child(1)
  > div
  > div
  > div:nth-child(1)
  > div {
  z-index: 99999;
  position: absolute;
}

.gradientBtn {
  border-radius: 10px;
  display: inline-block;
  white-space: nowrap;
  transition: 1s;
  background: transparent;
  color: black;
  text-align: left;
}
.spin {
  animation: roto 10s linear infinite;
}

.button {
  text-decoration: none;
}
#root > div > div.app-header.header-shadow > div.app-header__mobile-menu > div > button {
  text-decoration: none;
  background-color: transparent;
}

@keyframes roto {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(-0%) rotate(360deg);
  }
}
.gradientBtn:hover {
  transition: 1s;
  background-image: linear-gradient(to right, #00d2ff 100%, #3a7bd5 51%, #00d2ff 0%);
  background-position: right center;
}

.sidebarLinks {
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
}

.loadContentTransition {
  height: min-content;
  transition: height 2s;
}

p {
  font-family: "Montserrat", sans-serif;
  font-weight: 900;
}
h1 {
  font-family: "Montserrat", sans-serif;
  font-weight: 900;
}
h2 {
  font-family: "Montserrat", sans-serif;
  font-weight: 900;
}
h3 {
  font-family: "Montserrat", sans-serif;
  font-weight: 900;
}
h4 {
  font-family: "Montserrat", sans-serif;
  font-weight: 900;
}
.card {
  font-family: "Montserrat", sans-serif;
  font-weight: 900;
}
.card-header {
  font-family: "Montserrat", sans-serif;
  font-weight: 900;
}

.ThreeJSBG {

  overflow: hidden;
  white-space: nowrap;

}
